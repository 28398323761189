import React from "react";
import * as s from "../styles/globalStyles";
import Gradient from "rgt";
import * as p from "react-spotify-embed";
import i1 from "../assets/images/BTFD.webp"
import i2 from "../assets/images/questionmark.png"



function ProjectX() {
  return (
  
    <s.Container ai={"center"} jc={"center"} fd={"row"}>
    <s.Container1
                  ai={"center"}
                  jc={"center"}
                  fd={"column"}
                  style={{
                    
                    marginBottom: "0px",
                    marginTop: "10px",
                    borderRadius: "10px",
                    flexWrap: "wrap",

                    maxHeight: "550px",
                    minHeight: "384px",
                    minWidth: "307px",
                  }}
                >
                    
                    
                    
                    <p.Spotify link="https://open.spotify.com/playlist/2HVz9zwcErVCuoAFz9a6Tp?si=1712f48b42a74547" />
                    
                  </s.Container1>
                  
                  <s.Container1
                  ai={"center"}
                  jc={"center"}
                  fd={"column"}
                  style={{
                    marginLeft: "20px",
                    marginBottom: "0px",
                    marginTop: "10px",
                    borderRadius: "10px",
                    flexWrap: "wrap",

                    maxHeight: "550px",
                    minHeight: "384px",
                    minWidth: "307px",
                  }}
                >
                    <s.SpacerMedium />
                    <Gradient dir="left-to-right" from="#926f34" to="#ffe48b">
                      <s.TextDescription
                        style={{ textAlign: "center", fontSize: 20 }}
                      >
                        Project X
                      </s.TextDescription>
                      <s.SpacerMedium/>
                      <img src={i2} height="250px"/>
                    </Gradient>
                    <s.SpacerSmall />
                  </s.Container1>
                  </s.Container>
               
  )
}
export default ProjectX;
