//import i1 from "../assets/images/OpenSea2.png";
import React, { useState, Fragment } from "react";
import { FaBars } from "react-icons/fa";
import Gradient from "rgt";

import {
  Nav,
  NavContainer,
  NavLogo,
  NavItem,
  NavLinks,
  NavMenu,
  MobileIcon,
} from "./NavbarStyles";
import "../App.css";
const Navbar = () => {
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  return (
    <Fragment>
      <Nav className={colorChange ? "navbar colorChange" : "navbar"}>
        <NavContainer>
          <NavLogo href="#">
            <Gradient
              dir="left-to-right"
              from="#00DFD8"
              to="#007CF0"
            ></Gradient>
          </NavLogo>
          <MobileIcon>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks href="#">Top</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks href="https://rocstarsinc.myshopify.com/collections/all"  rel="noreferrer"
          title="Merch"
          target="_blank">Merch</NavLinks>
            </NavItem>           
          </NavMenu>
        </NavContainer>
      </Nav>
    </Fragment>
  );
};

export default Navbar;
