import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import * as s from "../styles/globalStyles";
import styled from "styled-components";
import Gradient from "rgt";
import { useNavigate } from "react-router";

export const SytledInput = styled.input`
  font-size: 22px;
  padding: 10px;
  width: 75px;
  margin: 10px;
  height: 50px;
  color: #ffe48b;
  outline: none;
  background: #111;
  border: #ffe48b;
  border-radius: 10px;
  border-color: #ffe48b;
  box-shadow: 0 0 10px #ffe48b;
`;

export const StyledButton = styled.button`
  font-size: 22px;
  width: 220px;
  height: 50px;
  border: #ffe48b;
  box-shadow: 0 0 10px #ffe48b;
  outline: none;
  color: #ffe48b;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  &:before {
    content: "";
    background: linear-gradient(
      45deg,
      #AE8625,
      #F7EF8A,
      #D2AC47,
      #EDC967,
      #DFBD69,
      #926F34,
      #F9F295,
      #E0AA3E,
      #B88A44
    );
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }

  &:active {
    color: #000;
  }

  &:active:after {
    background: transparent;
  }

  &:hover:before {
    opacity: 1;
  }

  &:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  @keyframes glowing {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
`;

function MintClaim() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [claimroyaltiesnow, setClaimRoyaltiesnow] = useState(false);
  const [freeClaim, setfreeClaim] = useState(false);
  const [gatedLogin, setgatedLogin] = useState(false);
  const [feedback, setFeedback] = useState("Cost: .022 ETH [10 max]");
  const [feedback2, setFeedback2] = useState("Free claim for Rocstar Jacket Holders");

  const [numentry, setnumentry] = useState("");
  const walletaddress = blockchain.account;
  const [CONFIG, SET_CONFIG] = useState({});

  const navigate = useNavigate();

  const Gated = () => {
    setFeedback(CONFIG.LOGINERROR);
    if (data.balanceOf >= 1 & data.totalSupply >=302) {
      setgatedLogin(true);
      navigate("./GatedContent");
    }
  };
  const claimroyal = () => {
    let cost = 0;
    let gasLimit = CONFIG.GAS_LIMIT;
    let chainID = CONFIG.chainID;
    let totalGasLimit = String(gasLimit);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(CONFIG.CLAIMING);
    setClaimRoyaltiesnow(true);
    blockchain.smartContract.methods
      .claimAllRoyalties()
      .send({
        gasLimit: String(gasLimit),
        address: blockchain.account,
        ID: String(chainID),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback(CONFIG.CLAIMERROR);
        setClaimRoyaltiesnow(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(CONFIG.CLAIMSUCCESS);
        setClaimRoyaltiesnow(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const claimNFTs = () => {
    if (numentry >= 1 ){ 
      let cost = data.mintPrice;
      let gasLimit = CONFIG.GAS_LIMIT;
      let totalCostWei = String(cost * numentry);
      let totalGasLimit = String(gasLimit * numentry);
      console.log("Cost: ", totalCostWei);
      console.log("Gas limit: ", totalGasLimit);
      setFeedback(CONFIG.MINTING);
      setClaimingNft(true);
      blockchain.smartContract.methods
        .mint(numentry)
        .send({
          gasLimit: String(totalGasLimit),
          to: CONFIG.CONTRACT_ADDRESS,
          from: blockchain.account,
          value: totalCostWei,
        })
        .once("error", (err) => {
          console.log(err);
          setFeedback(CONFIG.MINTERROR);
          setClaimingNft(false);
        })
        .then((receipt) => {
          console.log(receipt);
          setFeedback(CONFIG.MINTSUCCESS);
          setClaimingNft(false);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const claimFree = () => {    
      let gasLimit = CONFIG.GAS_LIMIT;
      let totalGasLimit = String(gasLimit * 1);
      console.log("Gas limit: ", totalGasLimit);
      setFeedback2(CONFIG.FREECLAIMING);
      setfreeClaim(true);
      blockchain.smartContract.methods
        .freeMint(1)
        .send({
          gasLimit: String(totalGasLimit),
          to: CONFIG.CONTRACT_ADDRESS,
          from: blockchain.account,
        })
        .once("error", (err) => {
          console.log(err);
          setFeedback2(CONFIG.FREECLAIMERROR);
          setfreeClaim(false);
        })
        .then((receipt) => {
          console.log(receipt);
          setFeedback2(CONFIG.FREECLAIMSUCCESS);
          setfreeClaim(false);
          dispatch(fetchData(blockchain.account));
        });
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      {blockchain.account === "" || blockchain.smartContract === null ? (
        <s.Container ai={"center"} jc={"center"}>
          <s.Container1
            ai={"center"}
            jc={"center"}
            fd={"row"}
            style={{
              marginLeft: "0px",
              marginBottom: "0px",
              marginTop: "10px",
              borderRadius: "10px",
              flexWrap: "wrap",

              maxHeight: "550px",
              minHeight: "250px",
              maxWidth: "400px",
              minWidth: "250px",
            }}
          >
            <StyledButton
              style={{ fontSize: 20, marginTop: 50 }}
              onClick={(e) => {
                e.preventDefault();
                dispatch(connect());
                getData();
              }}
            >
              <Gradient dir="left-to-right" from="#926f34" to="#ffe48b">
              {claimingNft ? "Connecting!" : "Connect Your Wallet"}
              </Gradient>
            </StyledButton>
            <Gradient dir="left-to-right" from="#926f34" to="#ffe48b">
              <s.TextDescription style={{ fontSize: 22, marginBottom: 10, textAlign: "center" }}>
                Follow the music to protect your investment!
              </s.TextDescription>
            </Gradient>
          </s.Container1>
          {blockchain.errorMsg !== "" ? (
            <>
              <s.SpacerSmall />
              <s.TextDescription style={{ textAlign: "center", color: "red", fontSize: 25}}>
                {blockchain.errorMsg}
              </s.TextDescription>
            </>
          ) : null}
        </s.Container>
      ) : (
        <s.Container ai={""} jc={""}>
          {Number(data.totalSupply) === 2222 ? (
            
              <s.Container ai={"center"} jc={"center"} fd={"row"}>
                <s.Container1
                  ai={"center"}
                  jc={"center"}
                  fd={"column"}
                  style={{
                    marginLeft: "0px",
                    marginBottom: "0px",
                    marginTop: "10px",
                    borderRadius: "8px",
                    flexWrap: "wrap",

                    maxHeight: "550px",
                    minHeight: "250px",
                    //maxWidth: "1920px",
                    minWidth: "400px",
                  }}
                >
                  <Gradient dir="left-to-right" from="#926f34" to="#ffe48b">
                    <s.TextTitle
                      style={{
                        textAlign: "center",
                        fontSize: "25px",
                        marginLeft: "0px",
                        marginTop: "7px",
                      }}
                    >
                      The sale has ended!
                    </s.TextTitle>
                  </Gradient>
                  <s.SpacerSmall />
                  <s.TextDescription
                    style={{
                      textAlign: "center",
                      fontSize: "25px",
                      marginLeft: "0px",
                    }}
                  >
                    <Gradient dir="left-to-right" from="#926f34" to="#ffe48b">
                      Get {CONFIG.NFT_NAME} on{""}
                    </Gradient>{" "}
                    {""}
                    <a
                      href={CONFIG.MARKETPLACE_LINK}
                      style={{ color: "#ffe48b", textDecoration: "none" }}
                    >
                      {CONFIG.MARKETPLACE}
                    </a>
                  </s.TextDescription>
                  <s.SpacerSmall />
                </s.Container1>

                <s.Container1
                    ai={"center"}
                    jc={"center"}
                    fd={"column"}
                    style={{
                      marginLeft: "20px",
                      marginBottom: "0px",
                      marginTop: "10px",
                      borderRadius: "10px",
                      flexWrap: "wrap",

                      maxHeight: "550px",
                      minHeight: "250px",
                      minWidth: "400px",
                    }}
                  >
                    <StyledButton disabled={gatedLogin ? 1 : 0} 
                    onClick={Gated}
                    >
                    <Gradient dir="left-to-right" from="#926f34" to="#ffe48b">
                      {gatedLogin ? "Loging In" : "Members Only"}
                      </Gradient>
                    </StyledButton>
                    <s.SpacerLarge />
                    <s.SpacerLarge />
                    <s.SpacerSmall />

                    <Gradient dir="left-to-right" from="#926f34" to="#ffe48b">
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          fontSize: 20,
                          marginTop: 24,
                          marginBottom: 14,
                        }}
                      >
                        You own {data.balanceOf} Room22 NFTs
                      </s.TextDescription>
                    </Gradient>
                  </s.Container1>
              </s.Container>
           
          ) : (
            <s.Container ai={""} jc={""}>
              {blockchain.account === "" ||
              blockchain.smartContract === null ? (
                <s.Container ai={"center"} jc={"center"}>
                  <s.TextDescription
                    style={{ textAlign: "center", fontSize: 20 }}
                  >
                    Connect to the ETH network
                  </s.TextDescription>

                  {blockchain.errorMsg !== "Connect to ETH network" ? (
                    <>
                      <s.TextDescription style={{ textAlign: "center" }}>
                        {blockchain.errorMsg}
                      </s.TextDescription>
                    </>
                  ) : null}
                </s.Container>
              ) : (
                <s.Container
                  ai={""}
                  jc={"center"}
                  fd={"row"}
                  style={{
                    marginLeft: "0px",
                    marginBottom: "0px",
                    marginTop: "0px",
                  }}
                >
                   {/* MINT SECTION */}

                   
                  <s.Container1
                    ai={"center"}
                    jc={"center"}
                    fd={"column"}
                    style={{
                      marginLeft: "0px",
                      marginBottom: "10px",
                      marginTop: "10px",
                      borderRadius: "10px",
                      flexWrap: "wrap",
                      maxHeight: "550px",
                      minHeight: "250px",
                      //maxWidth: "1920px",
                      minWidth: "400px",
                    }}
                  >
                    <s.SpacerXXXSmall />
                    
                    <StyledButton
                      disabled={claimingNft ? 1 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        claimNFTs(numentry);
                        getData();
                      }}
                    >
                      <Gradient dir="left-to-right" from="#926f34" to="#ffe48b">
                      {claimingNft ? "Minting" : "Mint"}
                      </Gradient>
                    </StyledButton>
                    <s.SpacerXXXSmall/>
                    <s.SpacerXXXXSmall/>
                    
                    
                    <SytledInput                 
                      id="num"
                      type="number"
                      min="1"
                      max={CONFIG.MAXMINTAMOUNT}
                      placeholder="0"
                      onChange={(e) => setnumentry(e.target.value)}
                    ></SytledInput>
                    
                    <Gradient dir="left-to-right" from="#926f34" to="#ffe48b">
                      <s.TextDescription
                        style={{ textAlign: "center", fontSize: 20 }}
                      >{data.totalSupply}/2222 Minted <s.SpacerXXXSmall/>
                        {feedback}
                      </s.TextDescription>
                    </Gradient>
                    <s.SpacerSmall />
                  </s.Container1>

                  <s.Container1
                    ai={"center"}
                    jc={"center"}
                    fd={"column"}
                    style={{
                      marginLeft: "20px",
                      marginBottom: "0px",
                      marginTop: "10px",
                      borderRadius: "10px",
                      flexWrap: "wrap",

                      maxHeight: "550px",
                      minHeight: "250px",
                      minWidth: "400px",
                    }}
                  >
                    <StyledButton disabled={gatedLogin ? 1 : 0} 
                    onClick={Gated}
                    >
                    <Gradient dir="left-to-right" from="#926f34" to="#ffe48b">
                      {gatedLogin ? "Loging In" : "Members Only"}
                      </Gradient>
                    </StyledButton>
                    <s.SpacerLarge />
                    <s.SpacerLarge />
                    <s.SpacerSmall />

                    <Gradient dir="left-to-right" from="#926f34" to="#ffe48b">
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          fontSize: 20,
                          marginTop: 24,
                          marginBottom: 14,
                        }}
                      >
                        You own {data.balanceOf} Room22 NFTs
                      </s.TextDescription>
                    </Gradient>
                  </s.Container1>
                  
                </s.Container>
              )}
            </s.Container>
          )}
        </s.Container>
      )}
    </s.Screen>
  );
}
export default MintClaim;
