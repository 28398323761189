import { useState } from "react";
import { fetchNFTs } from "./fetchNFTs";
import { useSelector } from "react-redux";
import styled from "styled-components";
import * as s from "../styles/globalStyles";
import { Card, Text} from "@nextui-org/react";
import Gradient from "rgt";
import NukaCarousel from "nuka-carousel";

export const StyledButton = styled.button`
  font-size: 22px;
  width: 220px;
  height: 50px;
  border: #ff0000;
  box-shadow: 0 0 10px #ffe48b;
  outline: none;
  color: #926f34;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  &:before {
    content: "";
    background: linear-gradient(
      45deg,
      #ae8625,
      #f7ef8a,
      #d2ac47,
      #edc967,
      #dfbd69,
      #926f34,
      #f9f295,
      #e0aa3e,
      #b88a44
    );
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }

  &:active {
    color: #000;
  }

  &:active:after {
    background: transparent;
  }

  &:hover:before {
    opacity: 1;
  }

  &:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  @keyframes glowing {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
`;

const Explore = () => {
  const [NFTs, setNFTs] = useState("");
  const blockchain = useSelector((state) => state.blockchain);
  const owner = blockchain.account;
  const contractAddress = "0xC7056c4fB2277fca49637d94A86579084c97a956";

  return (
    <s.Container ai={"center"} jc={"center"} fd={"column"}>
      <s.SpacerXXSmall />
      <s.Container
        ai={"center"}
        jc={"center"}
        style={{
          marginLeft: "0px",
          marginBottom: "0px",
          marginTop: "13px",
        }}
      >
        <StyledButton
          className
          onClick={() => {
            fetchNFTs(owner, contractAddress, setNFTs);
          }}
        >
          <Gradient
            dir="left-to-right"
            from="#926f34 "
            to="#ffe48b"
            width="50px"
          >
            View Your NFTs
          </Gradient>
        </StyledButton>
      </s.Container>
      <s.SpacerMedium />
      <s.Container
        ai={"center"}
        jc={"center"}
        style={{
          width: "835px",
          height: "224.72px",
          marginTop: "9px",
        }}
      >
        <NukaCarousel slidesToShow={5} cellSpacing={10} width="50px">
          {NFTs ? (
            NFTs.map((NFT, i) => {
              return (
                <a
                  style={{ textDecoration: "none" }}
                  href={NFT.media[0].gateway}
                  rel="noreferrer"
                  target="_blank"
                >
                  <Card
                    isHoverable
                    css={{
                      marginRight: "$1",
                      boxShadow: "0px 2px 30px #000000",
                      borderStyle: "inset",
                      borderColor: "#ffe48b",
                      borderRadius: "9px",
                      borderWidth: "3px",
                    }}
                    variant="bordered"
                  >
                    <Card.Image src={NFT.media[0].gateway} loading="lazy" />
                    <Card.Body css={{ background: "#000000" }}>
                      <Text css={{ color: "#ffe48b", fontSize: "14px" }} h2>
                        {NFT.title}
                      </Text>
                    </Card.Body>
                  </Card>
                </a>
              );
            })
          ) : (
            <div></div>
          )}
        </NukaCarousel>
      </s.Container>
    </s.Container>
  );
};

export default Explore;
