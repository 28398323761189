import React, { useEffect, useState, Fragment } from "react";
import * as s from "../styles/globalStyles";
import background from "../assets/images/background.webp";
import mobilebackground from "../assets/images/mobilebackground.webp";
import Footer from "./Footer";
import LogoLinks from "./LogoLinks";
import GatedContent2 from "./GatedContent2";
import Navbar from "./NavBar2";
import Explore from "./explore"
import ProjectX from "./ProjectX";



function GatedContent() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const imageUrl = windowWidth >= 1000 ? background : mobilebackground;

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };
        
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, []);

  return (
    <s.Container
      style={{
        backgroundColor: "black",
        backgroundImage: `url(${imageUrl})`,
        backgroundRepeat: "no-repeat",
       
      }}
    >
      <Fragment>
        <Navbar />
      </Fragment>
      <div id="#" />
      <s.Container2>
        <s.Container ai={"center"} jc={"center"}>
          <LogoLinks />
        </s.Container>

        <s.SpacerLarge />
        <s.Container ai={"center"} jc={"center"}>
          <GatedContent2 />
        </s.Container>

        <s.SpacerLarge />

        <s.Container
        ai={"center"}
        jc={"center"}
        style={{
          marginTop: "595px",
          marginBottom: "0px",
          position: "absolute",
        }}>
          <ProjectX/>
        </s.Container>
        
        <s.Container
          ai={"center"}
          jc={"center"}
          style={{
            marginTop: "1005px",
            marginBottom: "0px",
            position: "absolute",
          }}
          
        >
          <Explore />
        </s.Container>
        <s.SpacerXXXXLarge/>
        
      </s.Container2>
      <Footer />
    </s.Container>
  );
}
export default GatedContent;
