import React, { useEffect, useState} from "react";
import * as s from "../styles/globalStyles";

function Description() {
  const [CONFIG, SET_CONFIG] = useState({});
  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  return (
    <s.Container
      ai={"center"}
      jc={"center"}
      fd={""}
      style={{
        marginLeft: "0px",
        marginBottom: "0px",
        marginTop: "25px",
        borderRadius: "10px",
        flexWrap: "wrap",

        maxHeight: "250px",
        minHeight: "150px",
        maxWidth: "960px",
      }}
    >
      <s.TextDescription
        style={{ textAlign: "center", fontSize: 25, maxWidth: "900px" }}
      >
        {CONFIG.DESCRIPTION}
      </s.TextDescription>
    </s.Container>
  );
}
export default Description;
