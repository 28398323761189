import React, { useEffect, useState, Fragment  } from "react";
import * as s from "../styles/globalStyles";
import background from "../assets/images/background.webp";
import mobilebackground from "../assets/images/mobilebackground.webp";
import Footer from "./Footer";
import LogoLinks from "./LogoLinks";
import Description from "./Description";
import MintClaim from "./MintClaim";
import Team from "./Team"
import Navbar from "./NavBar"

function Home() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const imageUrl = windowWidth >= 1000 ? background : mobilebackground;

  useEffect(() => {
      const handleWindowResize = () => {
          setWindowWidth(window.innerWidth);
      };
      
      window.addEventListener('resize', handleWindowResize);

      return () => {
          window.removeEventListener('resize', handleWindowResize);
      }
  }, []);

  return (
    
    <s.Container
      style={{
        backgroundColor: "var(--black)",
        backgroundImage: `url(${imageUrl})`,
        backgroundRepeat: "repeat",
       
      }}
    >
      <Fragment>
        <Navbar />
      </Fragment>
      <div id="#" />
      <s.Container2>
        <s.Container ai={"center"} jc={"center"}>
          <LogoLinks />
        </s.Container>

        <s.SpacerLarge />
        <s.Container ai={"center"} jc={"center"}>
          <MintClaim />
        </s.Container>

        <s.SpacerLarge />
        <div id="about" />
        <s.Container
          ai={"center"}
          jc={"center"}
          style={{
            marginTop: "630px",
            marginBottom: "0px",
            position: "absolute",
          }}
        >
          <Description />
        </s.Container>
        <div id="team" />
        <s.Container
          ai={"center"}
          jc={"center"}
          style={{
            marginTop: "825px",
            marginBottom: "0px",
            position: "absolute",
          }}
        >
          <Team />
        </s.Container>
        <s.SpacerXXXXLarge/>
        
      </s.Container2>
      <Footer />
    </s.Container>
  );
}
export default Home;
