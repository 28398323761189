import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../redux/data/dataActions";
import * as s from "../styles/globalStyles";
import Gradient from "rgt";
import i1 from "../assets/images/Derek.webp";
import i2 from "../assets/images/Rocstar22.webp";
import i3 from "../assets/images/Pigstand.webp";
import i4 from "../assets/images/Didi.webp";
import i5 from "../assets/images/Oki.webp"
import ReactRoundedImage from "react-rounded-image";

function Team() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const [CONFIG, SET_CONFIG] = useState({});
  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Container ai={"center"} jc={"center"}>
      <s.SpacerSmall />
      <Gradient dir="left-to-right" from="#926f34" to="#ffe48b">
        <s.TextDescription
          style={{
            textAlign: "center",
            fontSize: 40,
            marginTop: 45,
            marginBottom: 0,
          }}
        >
          Team
        </s.TextDescription>
      </Gradient>
      <s.SpacerSmall />
      <s.Container
        ai={""}
        jc={"center"}
        fd={"row"}
        style={{
          flexWrap: "wrap",
        }}
       
      >
        {/* Rocstar22 */}
        <s.Container4
            ai={"center"}
            jc={""}
            fd={"column"}
            style={{
              marginLeft: "0px",
              marginBottom: "0px",
              marginTop: "10px",
              borderRadius: "10px",
              flexWrap: "wrap",

              maxHeight: "350px",
              minHeight: "350px",
              maxWidth: "256px",
              minWidth: "256px",
            }}
          >
            <a href="https://twitter.com/rocstar22_" rel="noreferrer"
          target="_blank">
          <ReactRoundedImage
                image={i2}             
                borderRadius="6"
                roundedColor=""
                imageWidth="250"
                imageHeight="250"
                roundedSize=""
                hoverColor=""
              />
            </a>
            <s.SpacerSmall/>
            <Gradient dir="left-to-right" from="#926f34" to="#ffe48b">
              <s.TextDescription style={{ fontSize: 18}}>
              Rocstar22 
              </s.TextDescription>
            </Gradient>
            <Gradient dir="left-to-right" from="#926f34" to="#ffe48b">
              <s.TextDescription style={{ fontSize: 20}}>
              Founder 
              </s.TextDescription>
            </Gradient>
          </s.Container4>

            <s.SpacerMedium/>

       
        {/* Derek */}
        <s.Container4
            ai={"center"}
            jc={""}
            fd={"column"}
            style={{
              marginLeft: "0px",
              marginBottom: "0px",
              marginTop: "10px",
              borderRadius: "10px",
              flexWrap: "wrap",
              maxHeight: "350px",
              minHeight: "350px",
              maxWidth: "256px",
              minWidth: "256px",
            }}
          >
            <a href="https://twitter.com/YesWeCoin_" rel="noreferrer"
          target="_blank">
          <ReactRoundedImage
                image={i1}
                borderRadius="6"
                roundedColor=""
                imageWidth="250"
                imageHeight="250"
                roundedSize=""
                hoverColor=""
              />
              </a>
              <s.SpacerSmall/>
            <Gradient dir="left-to-right" from="#926f34" to="#ffe48b">
              <s.TextDescription style={{ fontSize: 18}}>
              Derek “Yes We Coin” Jones 
              </s.TextDescription>
            </Gradient>
            <Gradient dir="left-to-right" from="#926f34" to="#ffe48b">
              <s.TextDescription style={{ fontSize: 20}}>
              Advisor 
              </s.TextDescription>
            </Gradient>
          
          </s.Container4>

            <s.SpacerMedium/>
            {/* Didi */}
            <s.Container4
            ai={"center"}
            jc={""}
            fd={"column"}
            style={{
              marginLeft: "0px",
              marginBottom: "0px",
              marginTop: "10px",
              borderRadius: "10px",
              flexWrap: "wrap",
              maxHeight: "350px",
              minHeight: "350px",
              maxWidth: "256px",
              minWidth: "256px",
            }}
          >
          <a href="https://twitter.com/BTFD_ImDiDi" rel="noreferrer"
          target="_blank">
          <ReactRoundedImage
                image={i4}
                borderRadius="6"
                roundedColor=""
                imageWidth="250"
                imageHeight="250"
                roundedSize=""
                hoverColor=""
              />
              </a>
              <s.SpacerSmall/>
            <Gradient dir="left-to-right" from="#926f34" to="#ffe48b">
              <s.TextDescription style={{ fontSize: 18}}>
              Anthony "Didi"  DIDIana 
              </s.TextDescription>
            </Gradient>
            <Gradient dir="left-to-right" from="#926f34" to="#ffe48b">
              <s.TextDescription style={{ fontSize: 20}}>
               Community Liaison
              </s.TextDescription>
            </Gradient>

          </s.Container4>

            <s.SpacerMedium/>
            {/* OkiemJednym */}
            <s.Container4
            ai={"center"}
            jc={""}
            fd={"column"}
            style={{
              marginLeft: "0px",
              marginBottom: "0px",
              marginTop: "10px",
              borderRadius: "10px",
              flexWrap: "wrap",
              maxHeight: "350px",
              minHeight: "350px",
              maxWidth: "256px",
              minWidth: "256px",
            }}
          >
          <a href="https://twitter.com/OkiemJednym_" rel="noreferrer"
          target="_blank">
          <ReactRoundedImage
                image={i5}
                borderRadius="6"
                roundedColor=""
                imageWidth="250"
                imageHeight="250"
                roundedSize=""
                hoverColor=""
              />
              </a>
              <s.SpacerSmall/>
            <Gradient dir="left-to-right" from="#926f34" to="#ffe48b">
              <s.TextDescription style={{ fontSize: 18}}>
              Paulina "Oki" Krajewska
              </s.TextDescription>
            </Gradient>
            <Gradient dir="left-to-right" from="#926f34" to="#ffe48b">
              <s.TextDescription style={{ fontSize: 20}}>
              Artist 
              </s.TextDescription>
            </Gradient>

          </s.Container4>

            <s.SpacerMedium/>
            {/* DerabbitagsNFT */}
            <s.Container4
            ai={"center"}
            jc={""}
            fd={"column"}
            style={{
              marginLeft: "0px",
              marginBottom: "0px",
              marginTop: "10px",
              borderRadius: "10px",
              flexWrap: "wrap",
              maxHeight: "350px",
              minHeight: "350px",
              maxWidth: "256px",
              minWidth: "256px",
            }}
          >
          <a href="https://twitter.com/DerabbitagsNFT" rel="noreferrer"
          target="_blank">
          <ReactRoundedImage
                image={i3}
                borderRadius="6"
                roundedColor=""
                imageWidth="250"
                imageHeight="250"
                roundedSize=""
                hoverColor=""
              />
              </a>
              <s.SpacerSmall/>
            <Gradient dir="left-to-right" from="#926f34" to="#ffe48b">
              <s.TextDescription style={{ fontSize: 18}}>
              Nicholas "Derabbit" Harris 
              </s.TextDescription>
            </Gradient>
            <Gradient dir="left-to-right" from="#926f34" to="#ffe48b">
              <s.TextDescription style={{ fontSize: 20}}>
              Dev 
              </s.TextDescription>
            </Gradient>

          </s.Container4>

            
      </s.Container>
      
    </s.Container>
  );
}
export default Team;
