import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../redux/data/dataActions";
import * as s from "../styles/globalStyles";
import i22 from "../assets/images/logo.webp";
import i11 from "../assets/images/discord.webp";
import i12 from "../assets/images/twitter.webp";
import i23 from "../assets/images/insta.webp";
import i24 from "../assets/images/youtube.webp";
import i25 from "../assets/images/tidal.webp";
import i26 from "../assets/images/spotify.webp";
import i27 from "../assets/images/applemusic.webp";
import i28 from "../assets/images/etherscan.webp";
import i29 from "../assets/images/opensea.webp"

function LogoLinks() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [CONFIG, SET_CONFIG] = useState({});
  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Container ai={"center"} jc={"center"}>
      <s.SpacerSmall />
      <img src={i22} height="200px" width="200px" meta name="viewport" alt=""/>
      <s.SpacerSmall />
      <s.Container
        ai={""}
        jc={"center"}
        fd={"row"}
        style={{
          marginTop: "5px",
          marginBottom: "5px",
          marginLeft: "0px",
          flexWrap: "",
          overflow: "",
          maxHeight: "45px",
          minHeight: "45px",
          maxWidth: "140px",
        }}
      >
        {/* <a
          href={CONFIG.MARKETPLACE_LINK}
          rel="noreferrer"
          title={CONFIG.MARKETPLACEICONMSG}
          target="_blank"
        >
          <img src={i13} alt="Opensea" width="45" height="45" border="0" />
        </a>
        &nbsp; */}
        <a
          href="https://open.spotify.com/artist/0HuixIZjKqri9XW6rtuneJ"
          rel="noreferrer"
          title="Spotify"
          target="_blank"
        >
          <img
            src={i26}
            alt="Spotify"
            width="45"
            height="45"
            border="0"
          />
        </a>
        &nbsp;
        <a
          href="https://tidal.com/browse/artist/32054352"
          rel="noreferrer"
          title="Tidal"
          target="_blank"
        >
          <img
            src={i25}
            alt="Tidal"
            width="45"
            height="45"
            border="0"
          />
        </a>
        &nbsp;
        
        <a
          href="https://music.apple.com/us/artist/rocstar22/1628413200"
          rel="noreferrer"
          title="Apple Music"
          target="_blank"
        >
          <img
            src={i27}
            alt="Apple Music"
            width="45"
            height="45"
            border="0"
            
          />
        </a>
        &nbsp;
        <a
          href={CONFIG.YOUTUBELINK}
          rel="noreferrer"
          title={CONFIG.YOUTUBEICONMSG}
          target="_blank"
        >
          <img
            src={i24}
            alt={CONFIG.YOUTUBE}
            width="45"
            height="45"
            border="0"
          />
        </a>
        &nbsp;
        <a
          href={CONFIG.INSTALINK}
          rel="noreferrer"
          title={CONFIG.INSTAICONMSG}
          target="_blank"
        >
          <img
            src={i23}
            alt={CONFIG.INSTA}
            width="45"
            height="45"
            border="0"
          />
        </a>
        &nbsp;
        <a
          href={CONFIG.DISCORD_LINK}
          rel="noreferrer"
          title={CONFIG.DISCORDICONMSG}
          target="_blank"
        >
          <img
            src={i11}
            alt={CONFIG.DISCORD}
            width="45"
            height="45"
            border="0"
          />
        </a>
        &nbsp;
        <a
          href={CONFIG.TWITTER_LINK}
          rel="noreferrer"
          title={CONFIG.TWITTERICONMSG}
          target="_blank"
        >
          <img
            src={i12}
            alt={CONFIG.TWITTER}
            width="45"
            height="45"
            border="0"
          />
        </a>
        &nbsp;
        <a
          href="https://etherscan.io/address/0xc7056c4fb2277fca49637d94a86579084c97a956"
          rel="noreferrer"
          title="Contract"
          target="_blank"
        >
          <img
            src={i28}
            alt="Contract"
            width="45"
            height="45"
            border="0"
          />
        </a>
        &nbsp;
        <a
          href="https://opensea.io/collection/room22"
          rel="noreferrer"
          title="Opensea"
          target="_blank"
        >
          <img
            src={i29}
            alt="Contract"
            width="45"
            height="45"
            border="0"
          />
        </a>
        {/* &nbsp;
        <a
          href="https://chainlist.org/?search=cronos"
          rel="noreferrer"
          title="Add Cronos to Metamask"
          target="_blank"
        >
          <img src={i14} alt="Metamask" width="45" height="45" border="0" />
        </a> */}
      </s.Container>
    </s.Container>
  );
}
export default LogoLinks;
